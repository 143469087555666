import React, { useState } from "react";
import styles from "./Ui.module.css";

function PasswordInput(props) {
  const [isHidden, setIsHidden] = useState(true);

  const hidePasswordToggle = () => {
    setIsHidden((isHidden) => !isHidden);
  };
  return (
    <div className={`${styles.input} ${props.className}`}>
      <label>
        {props.label} <span>*</span>
      </label>
      <div>
        <input
          className={props.invalid ? `${styles.invalidInput}` : ""}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value}
          type={props.type}
          name={props.name}
          placeholder={props.placeholder}
          required
        />

        {isHidden ? (
          <svg
            onClick={hidePasswordToggle}
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_440_1673)">
              <path
                d="M14.9019 16.3175C13.4362 17.2471 11.7358 17.7394 10.0002 17.7366C5.50687 17.7366 1.76854 14.5033 0.984375 10.2366C1.34273 8.29556 2.31907 6.52237 3.76771 5.18163L1.16021 2.57663L2.33937 1.39746L18.8385 17.8975L17.6594 19.0758L14.9019 16.3175ZM4.94604 6.36163C3.81355 7.39129 3.02464 8.74395 2.68604 10.2366C2.94629 11.3753 3.46876 12.4376 4.21188 13.3388C4.95499 14.24 5.89823 14.9553 6.9665 15.4277C8.03477 15.9001 9.19858 16.1167 10.3653 16.0601C11.532 16.0035 12.6694 15.6753 13.6869 15.1016L11.9969 13.4116C11.2774 13.8648 10.4254 14.06 9.58041 13.9653C8.73544 13.8706 7.94774 13.4915 7.34652 12.8903C6.74529 12.2891 6.36623 11.5014 6.27151 10.6564C6.17679 9.81146 6.37203 8.95939 6.82521 8.23996L4.94604 6.36163ZM10.7619 12.1766L8.06021 9.47496C7.91193 9.85241 7.87703 10.2649 7.9598 10.6619C8.04256 11.0589 8.23939 11.4231 8.52614 11.7099C8.81289 11.9966 9.1771 12.1934 9.57409 12.2762C9.97108 12.359 10.3836 12.3241 10.761 12.1758L10.7619 12.1766ZM17.3394 14.0633L16.1469 12.8716C16.7039 12.0777 17.1005 11.1826 17.3144 10.2366C17.0879 9.24473 16.6621 8.30925 16.0628 7.48704C15.4636 6.66483 14.7034 5.97307 13.8284 5.4538C12.9535 4.93453 11.9821 4.5986 10.9733 4.46643C9.96452 4.33426 8.93938 4.40861 7.96021 4.68496L6.64521 3.36996C7.68437 2.96163 8.81687 2.73663 10.0002 2.73663C14.4935 2.73663 18.2319 5.96996 19.016 10.2366C18.7607 11.6247 18.1868 12.9346 17.3394 14.0633ZM9.76937 6.49329C10.2998 6.46051 10.8311 6.54085 11.3282 6.72898C11.8252 6.9171 12.2765 7.20872 12.6523 7.5845C13.0281 7.96029 13.3197 8.41165 13.5079 8.90868C13.696 9.4057 13.7763 9.93704 13.7435 10.4675L9.76937 6.49329Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_440_1673">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="translate(0 0.236328)"
                />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg
            onClick={hidePasswordToggle}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1_1998)">
              <path
                d="M12.0002 3C17.3922 3 21.8782 6.88 22.8192 12C21.8792 17.12 17.3922 21 12.0002 21C6.60815 21 2.12215 17.12 1.18115 12C2.12115 6.88 6.60815 3 12.0002 3ZM12.0002 19C14.0396 18.9996 16.0185 18.3068 17.613 17.0352C19.2075 15.7635 20.3231 13.9883 20.7772 12C20.3214 10.0133 19.2051 8.24 17.6108 6.97003C16.0165 5.70005 14.0385 5.00853 12.0002 5.00853C9.96185 5.00853 7.98384 5.70005 6.38953 6.97003C4.79521 8.24 3.67892 10.0133 3.22315 12C3.67725 13.9883 4.79283 15.7635 6.3873 17.0352C7.98177 18.3068 9.96068 18.9996 12.0002 19ZM12.0002 16.5C10.8067 16.5 9.66209 16.0259 8.81817 15.182C7.97426 14.3381 7.50015 13.1935 7.50015 12C7.50015 10.8065 7.97426 9.66193 8.81817 8.81802C9.66209 7.97411 10.8067 7.5 12.0002 7.5C13.1936 7.5 14.3382 7.97411 15.1821 8.81802C16.026 9.66193 16.5002 10.8065 16.5002 12C16.5002 13.1935 16.026 14.3381 15.1821 15.182C14.3382 16.0259 13.1936 16.5 12.0002 16.5ZM12.0002 14.5C12.6632 14.5 13.2991 14.2366 13.7679 13.7678C14.2368 13.2989 14.5002 12.663 14.5002 12C14.5002 11.337 14.2368 10.7011 13.7679 10.2322C13.2991 9.76339 12.6632 9.5 12.0002 9.5C11.3371 9.5 10.7012 9.76339 10.2324 10.2322C9.76355 10.7011 9.50015 11.337 9.50015 12C9.50015 12.663 9.76355 13.2989 10.2324 13.7678C10.7012 14.2366 11.3371 14.5 12.0002 14.5Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_1998">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )}
      </div>
    </div>
  );
}

export default PasswordInput;
